<script>
// import { SimpleBar } from "simplebar-vue3"
// import { layoutComputed } from "@/state/helpers";
import axios from 'axios';
export default {
  data() {
    return {
      not_count: 0,
      not_loop: [],
      app_URL: process.env.VUE_APP_URL,
    };
  },
  // mounted() {
  //         this.fetchNotifData();
  //       },
  methods: {
    fetchNotifData() {
      var tken = localStorage.getItem("accessToken");
      console.log('first step with token ----- ', tken)
      axios
        .post(`/applications/kick_notif/`, {
          token: tken,
        })
        .then(response => {
          const data = response.data;
          console.log('notification data>>>>...........', data)
          this.not_count = data[1]
          this.not_loop = data[0]
        })
        .catch(error => {
          console.error(error);
        });
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    logout() {
      axios.post(this.app_URL + "api/logout").then(() => {
        const userType = atob(decodeURIComponent(JSON.parse(localStorage.getItem('userType'))));
        if (userType == "admin" || userType == "staff") {
          localStorage.removeItem("email");
          localStorage.removeItem("authenticatedUserId");
          localStorage.removeItem("userType");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("currentStep");
          this.$router.push({ path: '/login' });
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("authenticatedUserId");
          localStorage.removeItem("userType");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("currentStep");
          this.$router.push({ path: '/Applicant-login' });
        }

      })
    }
  },

};
</script>

<template>
  <header id="page-topbar">
    <div class="container">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <h1 class="navbar-brand-box">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/citizenship/whinchester.png" alt="Logo whinchester" height="55" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/citizenship/whinchester.png" alt="Logo whinchester" height="55" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/citizenship/whinchester.png" alt="Logo whinchester" height="55" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/citizenship/whinchester.png" alt="Logo whinchester" height="55" />
              </span>
            </router-link>
          </h1>

          <button id="toggle" type="button" class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
            @click="toggleMenu">
            <i class="fa fa-fw fa-bars"></i>
          </button>
        </div>


        <div class="d-flex">
          <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Search..." />
            <span class="bx bx-search-alt"></span>
          </div>
        </form>
          <b-dropdown class="d-inline-block d-lg-none ms-2" variant="black"
            menu-class="dropdown-menu-lg p-0 dropdown-menu-end" toggle-class="header-item noti-icon" right>
            <template v-slot:button-content>
              <i class="mdi mdi-magnify"></i>
            </template>

            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                  <div class="input-group-append">
                    <button class="btn btn-theme" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </b-dropdown> -->


          <b-dropdown menu-class="dropdown-menu-lg p-0 dropdown-menu-end" toggle-class="header-item noti-icon"
            variant="black">
            <template v-slot:button-content>
              <i class="bx bx-bell bx-tada 333"></i>
              <span class="badge bg-danger rounded-pill" v-if="not_count">{{ not_count }}</span>
            </template>

            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-0">
                    Notifications
                  </h6>
                </div>
                <div class="col-auto">
                  <a href="#" class="small"></a>
                </div>
              </div>
            </div>
            <SimpleBar style="max-height: 230px" v-for="i in not_loop" :key="i">
              <a href="javascript: void(0);" class="text-reset notification-item">
                <div class="d-flex border-bottom">
                  <div class="flex-grow-1">
                    <h6 class="mt-0 mb-1" v-if="i[3]">{{ i[0] }}</h6>
                    <h6 class="mt-0 mb-1 fw-bold" v-else>{{ i[0] }}</h6>
                    <div class="font-size-12 text-muted">
                      <p class="mb-1" v-if="i[3]">{{ i[1] }}</p>
                      <p class="mb-1 fw-bold" v-else>{{ i[1] }}</p>
                      <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ i[2] }}</p>
                    </div>
                  </div>
                </div>
              </a>
            </SimpleBar>
            <!-- <div class="p-2 border-top d-grid">
              <a class="btn btn-sm btn-theme font-size-14 text-center" href="javascript:void(0)">
                <i class="mdi mdi-arrow-right-circle me-1"></i>
                <span key="t-view-more">
                  View more</span>
              </a>
            </div> -->
          </b-dropdown>
          <div class="d-flex align-items-center ms-3">
            <button @click="logout" class="font-size-15 btn-sm btn btn-theme">
              <i class="mdi mdi-logout font-size-15 align-middle me-1"></i>
              Logout
            </button>
          </div>
          <!-- <b-dropdown right variant="black" toggle-class="header-item">
            <template v-slot:button-content>
              <img class="d-none rounded-circle header-profile-user" src="@/assets/images/users/avatar-1.jpg"
                alt="Header Avatar" />
              <span class="">{{ $store.state.authenticatedUser.firstName }}
                {{ $store.state.authenticatedUser.lastName }}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </template> -->
          <!-- item-->
          <!-- <b-dropdown-item>
              <router-link to="/contacts/profile" v-slot="{ navigate }" custom>
                <span @click="navigate" @keypress.enter="navigate">
                  <i class="bx bx-user font-size-16 align-middle me-1"></i>
                  Profile
                </span>
              </router-link>
            </b-dropdown-item>-->
          <!-- <b-dropdown-item>
              <router-link class="d-block text-dark" to="/setting">
                <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
                Setting
              </router-link>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider> 

            <router-link to="/logout" class="dropdown-item text-danger">
              <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              Logout
            </router-link>
          </b-dropdown> -->

        </div>
      </div>
    </div>
  </header>
</template>
