<template>
  <div>
    <HorizontalTopbar />
    <template v-if="$route.meta.nav === 1"></template>
    <template v-else>
      <HorizontalNav />
    </template>
  </div>
</template>

<script>
import HorizontalTopbar from "./horizontal-topbar.vue";
import HorizontalNav from "./horizontal-nav.vue";
import { layoutComputed } from "@/state/helpers";
import axios from "axios";

/**
 * Horizontal-layout
 */
export default {
  props: {},
  data() {
    return {
      app_URL: process.env.VUE_APP_URL
    };
  },
  computed: {
    ...layoutComputed,
  },
  mounted() {
    this.getAuthenticatedUser()
  },
  beforeMount() {
    this.getAuthenticatedUser()
  },
  components: {
    HorizontalTopbar,
    HorizontalNav
  },
  methods: {
    getAuthenticatedUser() {
      let data = new FormData();
      data.append('email', atob(decodeURIComponent(JSON.parse(localStorage.getItem('email')))));
      axios.post(this.app_URL + 'api/getAuthenticatedUser', data)
        .then(res => {
          this.$store.state.authenticatedUserId = res.data.data.id;
          this.$store.state.disclosure_id = res.data.disclosure_id.id;
          this.$store.state.authenticatedUser = res.data.data;
          this.$store.state.forFamily = res.data.forFamily;
          // console.log(res.data)
        })
        .catch((error) => {
          console.log(error)
          // Handle errors
        });
    },
  }
};
</script>
