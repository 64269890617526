export const menuItemsApplicant = [
    {
        id: 1,
        label: 'Dashboard',
        link: '/dashboard',
        icon: 'mdi mdi-home',
    },
    {
        id: 2,
        label: 'Invoices',
        link: '/applicantInvoice',
        icon: 'mdi mdi-file-pdf',
    },

    // {
    //     id: 2,
    //     label: 'Staff',
    //     link: '/Staff_add',
    //     icon: 'mdi mdi-account'
    // },
    // {
    //     id: 3,
    //     label: 'Applicant',
    //     link: '/applicants',
    //     icon: 'mdi mdi-account-group'
    // },
    // {
    //     id: 3,
    //     label: 'Notification',
    //     link: '/notifications',
    //     icon: 'mdi mdi-bell-ring-outline'
    // }
];

