export const menuItemsAdmin = [
    {
        id: 1,
        label: 'Dashboard',
        link: '/',
        icon: 'mdi mdi-home',
    },
    {
        id: 2,
        label: 'All Staff',
        link: '/allStaff',
        icon: 'mdi mdi-account-group-outline',
    },
    {
        id: 3,
        label: 'All Applicants',
        link: '/applicants',
        icon: 'mdi mdi-account-multiple-plus',
    },
    {
        id: 4,
        label: 'Notifications',
        link: '/adminNotifications',
        icon: 'mdi mdi-bell-ring-outline',
    },
    {
        id: 5,
        label: 'Invoices',
        link: '/invoice',
        icon: 'mdi mdi-file-pdf',
    },
    {
        id: 6,
        label: 'Settings',
        link: '/setting',
        icon: 'mdi mdi-cog',
    },


];

